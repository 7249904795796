<template>
  <section class="home w-100">
    <div class="d-flex flex-column align-items-center">
      <img
        alt="Zemen Solution AB - logo"
        width="200"
        :src="require('@/assets/zemensolutions.png')"
      />
      <div class="header">
        <h4 class="text-center text-secondary">
          Welcome to zemensolutions.com!
        </h4>
        <p class="text-muted text-center">
          We develop custom software based on your individual needs,
          requirements, and business specifications as determined during the
          product discovery phase.
        </p>
      </div>
      <div class="row services mt-3">
        <div class="col-md-3">
          <figure class="figure">
            <img
              :src="require('@/assets/services/web.png')"
              class="figure-img img-fluid rounded"
              alt="Web development"
              width="60"
            />
          </figure>
          <p class="text-muted">Web development</p>
        </div>
        <div class="col-md-3">
          <figure class="figure">
            <img
              :src="require('@/assets/services/apps.png')"
              class="figure-img img-fluid rounded"
              alt="Mobile & desktop apps"
              width="60"
            />
          </figure>
          <p class="text-muted">Mobile & desktop apps</p>
        </div>
        <div class="col-md-3">
          <figure class="figure">
            <img
              :src="require('@/assets/services/systems.png')"
              class="figure-img img-fluid rounded"
              alt="System development"
              width="60"
            />
          </figure>
          <p class="text-muted">System development</p>
        </div>
        <div class="col-md-3">
          <figure class="figure">
            <img
              :src="require('@/assets/services/training.png')"
              class="figure-img img-fluid rounded"
              alt="Personal training"
              width="60"
            />
          </figure>
          <p class="text-muted">Personal training</p>
        </div>
      </div>
      <div class="row text-center contact">
        <h5 class="">Let's discuss your project!</h5>
        <p class="d-flex justify-content-center align-items-center">
          <span class="badge bg-secondary fs-6">zemensolutions@gmail.com</span>
          <span class="or mx-3 fs-5">or</span>
          <span class="badge bg-secondary fs-6">+46 760 63 91 72</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
// import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "HomeView",
  components: {
    // NavbarMain,
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 75px 0;
  .header {
    margin-top: 30px;
    max-width: 46rem;
    padding: 15px 0;
  }
  .services {
    max-width: 57rem;
    margin-bottom: 25px;
    .col-md-3 {
      text-align: center;
      p {
        color: #99a3a4;
        font-weight: 600;
      }
    }
  }

  .contact {
    padding: 35px 0;
    .badge {
      padding: 10px 17px;
    }
  }
}
@media (max-width: 575.98px) {
  .services .col-md-3 {
    margin-bottom: 25px;
  }
  .contact .d-flex {
    // flex-direction: column;
    // align-items: center;
    .badge {
      font-size: 12px !important;
    }
  }
}
</style>
