<template>
  <router-view />
</template>

<style lang="scss">
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css?family=Raleway%3A400%2C%2C600%7CBerkshire+Swash%3A400&#038;display=fallback&#038;ver=2.6.0");
@import url("//fonts.googleapis.com/css?family=Lobster");
@import "@/assets/styles.scss";
</style>
